import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from 'urql'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import { loader } from 'graphql.macro'
import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import Alert from 'src/components/01-atoms/Alert'
import Container from 'src/components/01-atoms/Container'
import Fieldset from 'src/components/01-atoms/Fieldset'
import LoadingBar from 'src/components/01-atoms/LoadingBar'
import AlertList from 'src/components/02-molecules/AlertList'
import OrderConfirmationModal from 'src/components/03-organisms/OrderConfirmationModal'
import Cart from 'src/components/05-pages/Cart'

import MDashHead from 'src/pages/elements/MDashHead'
import useAppParams from 'src/utils/hooks/useAppParams'
import useCheckoutParams from 'src/utils/hooks/useCheckoutParams'

import {
  IGetOrderStatusQuery,
  IGetOrderStatusQueryVariables,
} from 'src/graphql/queries/getOrderStatus.types'
import {
  IPlaceOrderMutation,
  IPlaceOrderMutationVariables,
} from 'src/graphql/mutations/placeOrder.types'

import { isShippingAddressIncomplete } from 'src/utils/helpers/shipping'
import StripePayment from './elements/StripePayment'
import PurchaserInfo from './elements/PurchaserInfo'
import GiftMessage from './elements/GiftMessage'
import ShippingInfo from './elements/ShippingInfo'
import CartProductSelection, { TProductSelectionCartItem } from './elements/CartProductSelection'
import DeliveryOptions from './elements/DeliveryOptions'
import PromoCodeGiftCardEntry from './elements/PromoCodeGiftCardEntry'

import defaultFormSchema, { requiresCreditCardSchema } from './formSchema'
import { IOrderForm } from './types'

const getOrderStatusQuery = loader( 'src/graphql/queries/getOrderStatus.graphql' )
const placeOrderMutation = loader( 'src/graphql/mutations/placeOrder.graphql' )

const Checkout = () => {
  const stripe = useStripe()
  const elements = useElements()
  const cardElement = elements?.getElement( 'card' )
  const navigate = useNavigate()
  const { mdashAccountName, mdashAccountId, makeLinkUrls } = useAppParams()
  const { orderId, validOrderId } = useCheckoutParams()

  const [ cartItems, setCartItems ] = useState<TProductSelectionCartItem[]>([])

  const [ orderIsSubmitting, setOrderIsSubmitting ] = useState( false )
  const [ orderIsComplete, setOrderIsComplete ] = useState( false )
  const [ paymentError, setPaymentError ] = useState( '' )
  const [ orderPlacementErrors, setOrderPlacementErrors ] = useState<string[]>([])
  const [ hasCreditCard, setHasCreditCard ] = useState( false )
  const [ formSchema, setFormSchema ] = useState( defaultFormSchema )
  const [ isCreditCardRequired, setIsCreditCardRequired ] = useState( false )
  const [ canCheckout, setCanCheckout ] = useState( false )
  const [ needsPayment, setNeedsPayment ] = useState( true )

  const [
    { data: orderStatusData, fetching: fetchingOrderStatus, error: orderStatusError },
    refetchOrderStatus,
  ] = useQuery<IGetOrderStatusQuery, IGetOrderStatusQueryVariables>({
    query: getOrderStatusQuery,
    variables: {
      id: orderId,
      mdashAccountId,
    },
    pause: !validOrderId,
    requestPolicy: 'cache-and-network',
  })
  const orderStatus = orderStatusData?.order

  const [ , placeOrder ] = useMutation<IPlaceOrderMutation, IPlaceOrderMutationVariables>(
    placeOrderMutation
  )

  const appliedPromoCode = orderStatus?.appliedPromotion?.code || undefined
  const appliedGiftCardCodes = orderStatus?.appliedGiftCards?.map(( gc ) => gc.code ) ?? []
  const giftCardPayment = appliedGiftCardCodes.length > 0

  const tax = Number( orderStatus?.taxInCents ?? 0 )
  const shipping = Number( orderStatus?.shippingSubtotalInCents ?? 0 )
  const total = Number( orderStatus?.totalInCents ?? 0 )
  const promoAmount = Number( orderStatus?.appliedPromotion?.amountInCents ?? 0 )
  const giftCardAmount =
    orderStatus?.appliedGiftCards?.reduce(( acc, cur ) => acc + Number( cur.amountInCents ), 0 ) ?? 0
  const productSubtotal = Number( orderStatus?.productsSubtotalInCents ?? 0 )
  const subtotal =
    productSubtotal > 0
      ? orderStatus?.productsSubtotalInCents
      : total - shipping - tax + promoAmount + giftCardAmount

  const orderForm = useForm<IOrderForm>({
    resolver: zodResolver( formSchema ),
    mode: 'all',
    reValidateMode: 'onBlur',
  })
  const inStorePayment = orderForm.watch( 'inStorePayment', false )

  useEffect(() => {
    if ( !orderStatus ) return
    const paymentNotCovered =
      orderStatus.isReadyForCheckout && total > 0 && ( productSubtotal > 0 || shipping > 0 )
    const ccRequired = paymentNotCovered && !inStorePayment
    setIsCreditCardRequired( ccRequired )
    setFormSchema( ccRequired ? requiresCreditCardSchema : defaultFormSchema )
    setNeedsPayment( paymentNotCovered )
    const formIsValid = orderForm.formState.isValid && isEmpty( orderForm.formState.errors )
    setCanCheckout( formIsValid && ( hasCreditCard || !ccRequired ))
  })

  cardElement?.on( 'change', ( event ) => {
    setHasCreditCard( event.complete )
  })

  useEffect(() => {
    cardElement?.update({ disabled: !isCreditCardRequired })
  }, [ isCreditCardRequired ])

  useEffect(() => {
    if ( !( orderIsComplete && orderStatus?.isCompleted && cardElement )) return
    cardElement.clear()
    cardElement.destroy()
  }, [ orderIsComplete, orderStatus ])

  useEffect(() => {
    if ( isCreditCardRequired ) return
    orderForm.setValue( 'paymentPhone', '' )
    orderForm.setValue( 'nameOnCard', '' )
    cardElement?.clear()
    orderForm.clearErrors([ 'paymentPhone', 'nameOnCard' ])
    orderForm.unregister([ 'paymentPhone', 'nameOnCard' ])
  }, [ orderForm, orderStatus, isCreditCardRequired ])

  useEffect(() => {
    if ( orderPlacementErrors.length === 0 ) return
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [ orderPlacementErrors ])

  const submitOrderToBackend = ( variables: IPlaceOrderMutationVariables ) => {
    placeOrder( variables )
      .then(( response ) => {
        const errors = [
          response.error?.message ?? '',
          ...( response.data?.placeOrder?.errors ?? []),
        ].filter(( v ) => !!v )
        setOrderIsComplete( !!response.data?.placeOrder?.order?.isCompleted )
        if ( errors.length > 0 ) {
          setOrderPlacementErrors( errors )
        }
        setPaymentError( '' )
      })
      .catch(({ error }) => {
        setOrderPlacementErrors([ error.message ])
        setOrderIsComplete( false )
        throw error.message
      })
      .finally(() => {
        setOrderIsSubmitting( false )
      })
  }

  const handleSubmitOrder = ( values: FieldValues ) => {
    setOrderIsSubmitting( true )

    if ( !orderStatus?.isReadyForCheckout ) {
      setOrderIsComplete( false )
      setOrderIsSubmitting( false )
      return
    }

    if ( orderIsComplete || orderStatus?.isCompleted ) {
      setOrderPlacementErrors([ 'Order has already been completed.' ])
      setOrderIsSubmitting( false )
      return
    }

    const { nameOnCard, paymentPhone: phone } = values

    if ( !isCreditCardRequired ) {
      submitOrderToBackend({
        orderId,
        nameOnCard: '',
        phone: '',
        inStorePayment,
        giftCardPayment,
      })
      return
    }

    if ( !stripe || !elements ) {
      setPaymentError(
        'Stripe is currently unavailable. Please contact partner support for assistance.'
      )
      setOrderIsSubmitting( false )
      return
    }

    if ( !cardElement ) {
      setPaymentError(
        'Something went wrong with the Stripe library. Please contact partner support for assistance.'
      )
      setOrderIsSubmitting( false )
      return
    }

    stripe
      ?.createToken( cardElement )
      .then(
        ({ error, token }) => {
          if ( !token || error ) {
            setPaymentError(
              error.message ??
                'An error occurred while attempting to process the payment. Please contact partner support.'
            )
            setOrderIsSubmitting( false )
            return
          }

          submitOrderToBackend({
            orderId,
            nameOnCard,
            phone,
            stripeToken: token.id,
            inStorePayment,
            giftCardPayment,
          })
        },
        ( reason ) => {
          setPaymentError( reason )
          setOrderIsSubmitting( false )
        }
      )
      .catch(( err ) => {
        setPaymentError( err.response.data.message )
        setOrderIsSubmitting( false )
      })
  }

  return (
    <>
      <MDashHead pageTitle="Checkout" />
      <Container className="pt-6 pb-9">
        {!fetchingOrderStatus ? (
          <>
            {!!orderStatusError && !orderStatus && (
              <Alert type="error">{orderStatusError.message}</Alert>
            )}
            {!orderIsComplete && orderStatus?.isCompleted && (
              <Alert type="info" className="mb-4">
                This order has been completed, but you can still view its details.
              </Alert>
            )}
            {orderPlacementErrors.length > 0 && (
              <div data-testid="alert-order-placement-error">
                <AlertList type="error" className="mb-4" alerts={orderPlacementErrors} />
              </div>
            )}
            {!!orderStatus && (
              <FormProvider {...orderForm}>
                <Cart
                  isCompleted={orderIsComplete || !!orderStatus.isCompleted}
                  isSubmitting={orderIsSubmitting || fetchingOrderStatus}
                  handleSubmit={orderForm.handleSubmit( handleSubmitOrder, ( errors ) => {
                    const filteredErrors: string[] = Object.values( errors )
                      .map(( e ) => e.message ?? '' )
                      .filter(( m ) => !!m )
                    setOrderPlacementErrors( filteredErrors )
                  })}
                  merchantName={mdashAccountName}
                  isReadyForCheckout={canCheckout}
                  orderSummaryTotals={{
                    subtotal,
                    tax,
                    promoAmount,
                    giftCardAmount,
                    shipping,
                    total,
                  }}
                  promoCodeForm={
                    <PromoCodeGiftCardEntry
                      appliedPromoCode={appliedPromoCode}
                      appliedGiftCardCodes={appliedGiftCardCodes}
                    />
                  }
                >
                  <PurchaserInfo />
                  <div data-testid="shipping-info-form">
                    <ShippingInfo />
                  </div>
                  <CartProductSelection
                    refetchOrderStatus={refetchOrderStatus}
                    cartItems={cartItems}
                    setCartItems={setCartItems}
                  />
                  <div data-testid="delivery-options-form">
                    <DeliveryOptions
                      hasShippingAddress={
                        orderStatus.isValidShippingAddress ||
                        !isShippingAddressIncomplete( orderForm.getValues())
                      }
                      shouldGetDeliveryOptions={cartItems.length > 0}
                    />
                  </div>
                  {/* {priceAdjustmentsForm && (
                        <SectionCard size="small" title="Price Adjustments">
                          {priceAdjustmentsForm}
                        </SectionCard>
                      )} */}
                  <GiftMessage />
                  {!orderStatus.isCompleted && !orderIsComplete && (
                    <div data-testid="payment-form">
                      <Fieldset disabled={orderIsSubmitting || !needsPayment}>
                        <StripePayment required={isCreditCardRequired} error={paymentError} />
                      </Fieldset>
                    </div>
                  )}
                </Cart>
              </FormProvider>
            )}
          </>
        ) : (
          !orderStatus?.id && <LoadingBar current={99} total={100} />
        )}
      </Container>
      <OrderConfirmationModal
        title={`Order ${orderId} has been placed!`}
        open={orderIsComplete}
        primaryButtonFn={() => navigate( makeLinkUrls().manifestBase )}
        secondaryButtonFn={() => navigate( makeLinkUrls().checkout( 'new' ))}
      />
    </>
  )
}

export default Checkout
