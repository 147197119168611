import { loader } from 'graphql.macro'
import { FC, useEffect, useState } from 'react'

import DeliveryOptionsFormWrapper from 'src/components/01-atoms/DeliveryOptionsFormWrapper'
import Fieldset from 'src/components/01-atoms/Fieldset'
import SectionCard from 'src/components/01-atoms/SectionCard'
import AlertList from 'src/components/02-molecules/AlertList'
import DeliveryOptionsForm, {
  TDeliveryOptionCartItem,
} from 'src/components/03-organisms/DeliveryOptionsForm'

import {
  IUpdateDeliveryDateMutation,
  IUpdateDeliveryDateMutationVariables,
} from 'src/graphql/mutations/updateDeliveryDate.types'
import { IGetOrderDeliveryOptionsQuery } from 'src/graphql/queries/getOrderDeliveryOptions.types'
import { dateForManifest, stringAsDate } from 'src/utils/helpers/date'

import useAppParams from 'src/utils/hooks/useAppParams'
import useManageOrderPartial from 'src/utils/hooks/useManageOrderPartial'
import ArrayElement from 'src/utils/types/ArrayElement'

const getDeliveryOptionsQuery = loader( 'src/graphql/queries/getOrderDeliveryOptions.graphql' )
const updateDeliveryOptionsMutation = loader( 'src/graphql/mutations/updateDeliveryDate.graphql' )

type TDeliveryOptionsPackage = ArrayElement<IGetOrderDeliveryOptionsQuery['order']['packages']>
type PackageDeliveryOnly = Omit<IUpdateDeliveryDateMutationVariables, 'orderId'>

interface IDeliveryOptionsProps {
  hasShippingAddress?: boolean
  shouldGetDeliveryOptions?: boolean
}

const DeliveryOptions: FC<IDeliveryOptionsProps> = ({
  hasShippingAddress = false,
  shouldGetDeliveryOptions = false,
}) => {
  const { displaySku } = useAppParams()
  const [ packages, setPackages ] = useState<TDeliveryOptionsPackage[]>([])

  const {
    data,
    fetching: fetchingOrderPartial,
    errors,
    update,
    refetch,
  } = useManageOrderPartial<
    IGetOrderDeliveryOptionsQuery,
    IUpdateDeliveryDateMutation,
    PackageDeliveryOnly
  >( getDeliveryOptionsQuery, updateDeliveryOptionsMutation, !shouldGetDeliveryOptions )

  useEffect(() => {
    setPackages( data?.order.packages ?? [])
  }, [ data ])

  const fetching = fetchingOrderPartial || !data

  useEffect(() => {
    if ( !shouldGetDeliveryOptions || fetching ) return
    refetch()
  }, [ shouldGetDeliveryOptions ])

  return (
    <Fieldset disabled={!shouldGetDeliveryOptions || fetching}>
      <SectionCard size="small" title="Delivery Options" error={errors.length > 0}>
        {errors.length > 0 && <AlertList alerts={errors} type="error" className="mb-4" />}
        <DeliveryOptionsFormWrapper hasShippingAddress={hasShippingAddress}>
          {packages.length > 0
            ? packages?.map(( pkg: TDeliveryOptionsPackage ) => {
                if ( !pkg || !pkg.id || !pkg.cartItems ) return null
                const cartItems = pkg.cartItems.map(
                  ({ id, product, selectedProductOptions }) =>
                    ({
                      id,
                      name: product.name,
                      sku: displaySku && product.sku,
                      selectedOptions: selectedProductOptions.map(( po ) => ({
                        id: po.productOptionId,
                        name: `${po.variance?.name && `${po.variance?.name}: `}${
                          po.productOption?.name
                        }`,
                      })),
                    } as TDeliveryOptionCartItem )
                )
                const selectedDate = pkg.requestedDeliveryOn
                  ? stringAsDate( pkg.requestedDeliveryOn )
                  : undefined

                return (
                  <div
                    data-testid={`suborder-${pkg.id}-delivery-options`}
                    key={`suborder-${pkg.id}-delivery-options`}
                  >
                    <SectionCard title={`Suborder #${pkg.id}`} size="small">
                      <DeliveryOptionsForm
                        selected={selectedDate}
                        cartItems={cartItems}
                        shippingCalendar={pkg.shippingCalendarData || []}
                        handleChange={( date ) => {
                          if ( date === selectedDate ) return
                          update({ packageId: pkg.id, deliveryDate: dateForManifest( date ) })
                        }}
                      />
                    </SectionCard>
                  </div>
                )
              })
            : undefined}
        </DeliveryOptionsFormWrapper>
      </SectionCard>
    </Fieldset>
  )
}

export default DeliveryOptions
