import { FC, Fragment, ReactNode } from 'react'
import { kebabCase } from 'lodash'

interface IDetailsListProps {
  list: ({
    label: string
    value: string | ReactNode
  } | null )[]
}

const DetailsList: FC<IDetailsListProps> = ({ list }) => (
  <div className="grid grid-cols-3 gap-4">
    {list.map(
      ( li ) =>
        li && (
          <Fragment key={`detail-${kebabCase( li.label )}`}>
            <div className="font-bold col-span-1">{li.label}</div>
            <div className="col-span-2">{li.value}</div>
          </Fragment>
        )
    )}
  </div>
)

export default DetailsList
