import { FC } from 'react'
import classNames from 'classnames'
import { ReactComponent as ShipToCanada } from 'src/assets/icons/flags/canada.svg'

export enum PackageType {
  SUBSCRIPTION = 'Subscription',
  CORPORATE = 'Corporate',
  IN_STORE = 'In-Store',
  WHITE_LABEL = 'White Label',
  RESHIPMENT = 'Re-Shipment',
  CANCELED = 'Canceled',
  GENERIC_WHITE_ON_BLACK = 'Generic White on Black',
  GENERIC_LIGHT_GRAY = 'Generic Light Gray',
  SHIP_TO_CANADA = 'Canada',
}

interface IPackageTagProps {
  /**
   * The type of package.
   */
  packageType: PackageType
  isGrayscale?: boolean
  label?: string
}

const PackageTag: FC<IPackageTagProps> = ({ packageType, isGrayscale, label }) => {
  const packageClasses = {
    [PackageType.SUBSCRIPTION]: 'text-emerald-700 bg-emerald-100',
    [PackageType.CORPORATE]: 'text-violet-600 bg-violet-100',
    [PackageType.IN_STORE]: 'text-slate-600 bg-slate-100',
    [PackageType.WHITE_LABEL]: 'text-orange-700 bg-orange-100',
    [PackageType.RESHIPMENT]: 'text-pink-600 border-pink-600 bg-white border border-solid',
    [PackageType.CANCELED]: 'text-red-600 bg-red-100',
    [PackageType.GENERIC_WHITE_ON_BLACK]: 'text-gb-gray-50 bg-gb-gray-900',
    [PackageType.GENERIC_LIGHT_GRAY]: 'text-gb-gray-900 border-gb-gray-900 border',
    [PackageType.SHIP_TO_CANADA]: 'text-gb-gray-900 border-gb-gray-900 border pr-1.5',
  }

  return (
    <div
      className={classNames(
        isGrayscale
          ? 'text-gray-900 border-gray-900 border border-solid'
          : packageClasses[packageType],
        'flex items-center gap-1 py-0.5 px-1 leading-tight text-2xs rounded-sm font-normal'
      )}
    >
      {packageType === PackageType.SHIP_TO_CANADA && (
        <ShipToCanada className="w-4 h-4 -my-2 ml-0.5" />
      )}
      {label || packageType}
    </div>
  )
}

export default PackageTag
