import { FC, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import useAppParams from 'src/utils/hooks/useAppParams'

const AdminLanding: FC = () => {
  const { isAdmin, mdashAccountId } = useAppParams()
  const navigate = useNavigate()

  useEffect(() => {
    if ( mdashAccountId && !isAdmin ) {
      navigate( `/${mdashAccountId}/manifest` )
    }
  }, [ isAdmin, mdashAccountId ])

  return <Outlet />
}

export default AdminLanding
