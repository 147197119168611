import { FC } from 'react'

import Logo, { LogoType } from 'src/components/01-atoms/Logo'
import PackageInfo, { IPackageInfoProps } from 'src/components/02-molecules/PackageInfo'
import { IPackageContentsProps } from 'src/components/02-molecules/PackageContents'
import PackageRecipient, {
  IPackageRecipientProps,
} from 'src/components/02-molecules/PackageRecipient'
import PackageTagList, { IPackageTagListProps } from 'src/components/02-molecules/PackageTagList'
import PrintableChecklists from 'src/components/02-molecules/PrintableChecklists'
import StatusIconWrapper from 'src/components/01-atoms/StatusIconWrapper'
import PrintablePage, { PageSize } from 'src/components/01-atoms/PrintablePage'

interface IFulfillmentSheetUsLetterProps extends IPackageInfoProps {
  /**
   * Whether or not to show the product SKUs in the table.
   */
  displaySku?: boolean

  /**
   * The information for the package recipient.
   */
  recipient: IPackageRecipientProps

  /**
   * Which tags exist on the package.
   */
  tags: IPackageTagListProps

  /**
   * The list of products in the package.
   */
  products: IPackageContentsProps['lineItems']

  /**
   * The list of subproducts in the package.
   */
  subproducts: IPackageContentsProps['lineItems']

  /**
   * The list of addons in the package.
   */
  addons: IPackageContentsProps['lineItems']
}

const USLetter: FC<IFulfillmentSheetUsLetterProps> = ({
  displaySku,
  recipient,
  tags,
  products,
  subproducts,
  addons,
  ...packageInfoProps
}) => (
  <PrintablePage size={PageSize.US_LETTER_LOOSE}>
    <div className="flex">
      <div className="pr-6 w-3/4">
        <div className="flex">
          <Logo type={LogoType.STACKED} className="w-auto h-[1in] -mt-4 pr-6" />
          <div>
            <div className="flex ml-6 py-2 px-3 text-xs border border-gb-gray-400 border-solid rounded">
              <StatusIconWrapper level="info">
                <span>
                  Note that this sheet is only meant to be used to help you fulfill the order.{' '}
                  <b>Do not include this sheet in the box.</b>
                </span>
              </StatusIconWrapper>
            </div>
          </div>
        </div>
        <div>
          <div className="font-bold uppercase text-xl pb-4">Fulfillment Sheet</div>
          <div data-testid="checklist-products">
            <PrintableChecklists
              border
              displayQtyLast
              entities={products}
              displaySku={displaySku}
              title="Product Summary"
              mainColumnTitle="Product"
            />
          </div>
          {subproducts.length > 0 && (
            <div className="pb-6" data-testid="checklist-subproducts">
              <PrintableChecklists
                entities={subproducts}
                displayCheckbox
                displaySku={displaySku}
                title="To Pack"
                mainColumnTitle="Subproduct"
              />
            </div>
          )}
          {addons.length > 0 && (
            <div className="pb-6" data-testid="checklist-addons">
              <PrintableChecklists
                entities={addons}
                displayCheckbox
                displaySku={displaySku}
                title="Add Ons To Pack"
                mainColumnTitle="Subproduct"
              />
            </div>
          )}
        </div>
      </div>

      <div className="w-1/4">
        <PackageInfo {...packageInfoProps} version={1} showBarcode />
        <div className="py-4">
          <PackageRecipient {...recipient} />
        </div>
        <div className="pb-2">
          <PackageTagList {...tags} isGrayscale isInline />
        </div>
      </div>
    </div>
  </PrintablePage>
)

export default USLetter
