import { FC } from 'react'
import { IAddress } from 'src/utils/types/IAddress'

interface IAddressProps {
  address: IAddress
}

const Address: FC<IAddressProps> = ({
  address: {
    firstName,
    lastName,
    company,
    emails,
    phone,
    street1,
    street2,
    city,
    state,
    postalCode,
  },
}) => (
  <>
    {firstName && lastName && (
      <p>
        {firstName} {lastName}
      </p>
    )}
    {company && <p>{company}</p>}
    <p>{street1}</p>
    {street2 && <p>{street2}</p>}
    <p>
      {city}, {state} {postalCode}
    </p>
    {emails && emails.length > 0 && <p>{emails.join( ', ' )}</p>}
    {phone && <p>{phone}</p>}
  </>
)

export default Address
