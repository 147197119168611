import { FC, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAlarmExclamation } from '@fortawesome/pro-duotone-svg-icons'
import Modal, { IModalProps } from 'src/components/02-molecules/Modal'
import Markdown from 'markdown-to-jsx'
import Button from 'src/components/01-atoms/Button'

import './styles.css'

export interface IMegaphoneModalProps extends IModalProps {
  /**
   * String to be rendered as headline
   */
  title?: string | null

  /**
   * String to be rendered as markdown
   */
  messageBody: string

  /**
   * String to be rendered as button
   */
  actionButtonText?: string | null
}

const MegaphoneModal: FC<IMegaphoneModalProps> = ({
  title = 'This is Very Important',
  messageBody,
  actionButtonText = 'Got it',
  ...modalProps
}) => {
  const [ isOpen, setIsOpen ] = useState( true )

  return (
    <Modal
      {...modalProps}
      open={isOpen}
      handleClose={() => setIsOpen( false )}
      contentClassName="md:center w-10/12 sm:max-w-xl lg:max-w-2xl rounded p-0"
    >
      <div data-testid="megaphone-modal" className="md:flex md:flex-col md:center text-sm w-full">
        <div className="bg-red-500 w-full flex flex-col center h-16 mb-12">
          <div className="bg-red-100 text-red-600 aspect-square w-20 h-20 rounded-full flex center -mb-16">
            <FontAwesomeIcon icon={faAlarmExclamation} size="3x" />
          </div>
        </div>
        <div className="text-center px-12">
          <p className="font-semibold text-3xl mb-6">{title}</p>
          <div className="text-base markdown" data-testid="markdown-text">
            <Markdown>{messageBody}</Markdown>
          </div>
        </div>
        <Button
          data-testid="megaphone-modal-action-button"
          onClick={() => setIsOpen( false )}
          className="my-10 mx-auto"
          outline
        >
          {actionButtonText}
        </Button>
      </div>
    </Modal>
  )
}

export default MegaphoneModal
