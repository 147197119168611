import { FC, PropsWithChildren } from 'react'
import { Root, Item, AccordionSingleProps } from '@radix-ui/react-accordion'
import Card from 'src/components/01-atoms/Card'

const CardAccordion: FC<PropsWithChildren<Omit<AccordionSingleProps, 'type'>>> = ({
  children,
  className,
  ...accordionProps
}) => (
  <Card className={`px-4 py-2 ${className}`}>
    <Root {...accordionProps} type="single" className="overflow-hidden" collapsible>
      <Item value="card">{children}</Item>
    </Root>
  </Card>
)

export default CardAccordion
