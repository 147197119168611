import { FC, PropsWithChildren, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'urql'
import { loader } from 'graphql.macro'
import Skeleton from 'react-loading-skeleton'

import MDashHead from 'src/pages/elements/MDashHead'
import Alert from 'src/components/01-atoms/Alert'
import Container from 'src/components/01-atoms/Container'
import PackageDetail from 'src/components/05-pages/PackageDetail'

import {
  IGetPackageInfoQuery,
  IGetPackageInfoQueryVariables,
} from 'src/graphql/queries/getPackageInfo.types'
import { stringAsDate } from 'src/utils/helpers/date'
import useAppParams from 'src/utils/hooks/useAppParams'
import classNames from 'classnames'
import { useGetCustomerChoices } from 'src/utils/hooks/useGetCustomerChoices'
import CancelPackageModal from './elements/CancelPackageModal'
import EditDeliveryDateModal from './elements/EditDeliveryDateModal'
import EditGiftMessageModal from './elements/EditGiftMessageModal'
import ProtectedByPermission from './elements/ProtectedByPermission'

const EditOptionsWrapper: FC<PropsWithChildren<{}>> = ({ children }) => (
  <ProtectedByPermission
    userPermissions={[ 'canEditDeliveryDate', 'canCancelSuborders', 'canEditGiftMessages' ]}
  >
    {children}
  </ProtectedByPermission>
)

const OrderAgainWrapper: FC<PropsWithChildren<{}>> = ({ children }) => (
  <ProtectedByPermission userPermissions={[ 'canUseGuac' ]}>{children}</ProtectedByPermission>
)

const getPackageInfo = loader( 'src/graphql/queries/getPackageInfo.graphql' )

enum ModalType {
  EDIT_DELIVERY_DATE,
  EDIT_GIFT_MESSAGE,
  CANCEL_PACKAGE,
}

const PackageDetailPage: FC = () => {
  const { packageId } = useParams()
  const {
    makeLinkUrls,
    mdashAccountId,
    isAdmin,
    displaySku,
    withFacilityParams,
    isMultiShippingFacility,
    merchantUserPermissions,
    isPickUpEnabled,
  } = useAppParams()

  const [ modalOpen, setModalOpen ] = useState<ModalType>()
  const closeAllModals = () => setModalOpen( undefined )

  const [{ fetching, error, data }] = useQuery<IGetPackageInfoQuery, IGetPackageInfoQueryVariables>(
    {
      query: getPackageInfo,
      variables: {
        orderId: packageId!,
        mdashAccountId,
      },
      pause: Number( mdashAccountId || 0 ) < 1,
    }
  )

  const pkg = data?.package
  const links = makeLinkUrls( pkg?.toShipOn )

  const { data: customerChoicesData } = useGetCustomerChoices({
    toShipOn: pkg?.toShipOn,
    packageIds: [ packageId! ],
  })
  const hasGiftMessage = !!pkg?.giftMessage?.body || !!pkg?.giftMessage?.from

  const editPackageOptions = []
  if (( isAdmin || merchantUserPermissions?.canEditDeliveryDate ) && pkg?.isDeliveryDateEditable ) {
    editPackageOptions.push({
      to: '',
      text: 'Edit Delivery Date',
      onClick: () => setModalOpen( ModalType.EDIT_DELIVERY_DATE ),
    })
  }
  if (( isAdmin || merchantUserPermissions?.canEditGiftMessages ) && pkg?.isGiftMessageEditable ) {
    editPackageOptions.push({
      to: '',
      text: `${hasGiftMessage ? 'Edit' : 'Add'} Gift Message`,
      onClick: () => setModalOpen( ModalType.EDIT_GIFT_MESSAGE ),
    })
  }
  if (( isAdmin || merchantUserPermissions?.canCancelSuborders ) && pkg?.isCancelable ) {
    editPackageOptions.push({
      to: '',
      text: 'Cancel Package',
      onClick: () => setModalOpen( ModalType.CANCEL_PACKAGE ),
    })
  }

  return (
    <>
      <MDashHead pageTitle={`Order #${packageId}`} />
      <div className={classNames({ 'bg-yellow-100 flex-grow': pkg?.gbmcState === 'canceled' })}>
        <Container className="pt-6 pb-9">
          {fetching && <Skeleton height="50vh" />}
          {!fetching && ( !data || error ) && <Alert type="error">{error?.message}</Alert>}

          {pkg && (
            <>
              <PackageDetail
                package={{
                  id: pkg.id,
                  orderId: pkg.orderId,
                  completedAt: stringAsDate( pkg.completedAt ),
                  recipient: `${pkg.deliveryFirstName} ${pkg.deliveryLastName}`,
                  shipTo: `${pkg.deliveryCity}, ${pkg.deliveryState}`,
                  shipDate: stringAsDate( pkg.toShipOn ),
                  deliveryDate: stringAsDate( pkg.requestedDeliveryOn ),
                  isCanceled: pkg.gbmcState === 'canceled',
                  labelDiscardedAt:
                    pkg.currentShippingLabel?.discardedAt &&
                    stringAsDate( pkg.currentShippingLabel?.discardedAt ),
                  labelPrintedAt:
                    pkg.currentShippingLabel?.printedAt &&
                    stringAsDate( pkg.currentShippingLabel?.printedAt ),
                  labelHasBeenGenerated:
                    !!pkg?.currentShippingLabel && !!pkg?.currentShippingLabel.trackingCode!,
                  labelIsPending: !pkg?.currentShippingLabel,
                  shippingFacility: pkg.shippingFacility?.name,
                  fulfillmentSheetPrintedAt:
                    pkg.packingSheetPrintedAt && stringAsDate( pkg.packingSheetPrintedAt ),
                  pickUpLocation: pkg.pickUpLocation?.name,
                }}
                orderType={{
                  isCorporateOrder: pkg.isCorporateOrder,
                  isReshipment: pkg.isReshipment,
                  isInStoreOrder: pkg.isInStoreOrder,
                  isWhitelabel: pkg.isWhitelabel,
                  isSubscription: pkg.isSubscription,
                }}
                corporateOrderCompanyName={pkg.corporateOrderCompanyName}
                giftMessage={pkg?.giftMessage!}
                shippingLabel={pkg?.currentShippingLabel!}
                printLinks={{
                  shippingLabel: pkg?.currentShippingLabel
                    ? !pkg.currentShippingLabel.printedAt
                      ? `${links.printLabels}?id=${pkg.id}`
                      : `${links.confirmReprintLabels}?id=${pkg.id}`
                    : '',
                  fulfillmentSheet: `${links.printFulfillmentSheets}?id=${pkg.id}`,
                }}
                lineItems={pkg?.lineItems.map(
                  ({ id, name, quantityOrWeight, specialInstructions }) => {
                    const lineItemCustomerChoices =
                      customerChoicesData?.lineItemsCustomerChoices.find(( x ) => x.id === id )

                    return {
                      id,
                      name,
                      quantity: quantityOrWeight,
                      productOptions: lineItemCustomerChoices?.customerChoices?.map(( x ) => x.name ),
                      sku: displaySku
                        ? lineItemCustomerChoices?.merchantSkus?.map(
                            ( x ) => `${Number( x.quantity.toFixed( 1 ))} × ${x.name}`
                          )
                        : undefined,
                      specialInstructions,
                    }
                  }
                )}
                backLink={{
                  pathname: makeLinkUrls().manifest,
                  search: withFacilityParams(),
                }}
                showLinkToGbmc={isAdmin}
                showFacilityLink={isMultiShippingFacility || isPickUpEnabled}
                editPackageOptions={editPackageOptions}
                EditOptionsWrapper={EditOptionsWrapper}
                OrderAgainWrapper={OrderAgainWrapper}
              />
              <EditDeliveryDateModal
                open={modalOpen === ModalType.EDIT_DELIVERY_DATE}
                handleClose={closeAllModals}
                selected={stringAsDate( pkg.requestedDeliveryOn )}
                packageId={pkg.id}
              />
              <EditGiftMessageModal
                open={modalOpen === ModalType.EDIT_GIFT_MESSAGE}
                handleClose={closeAllModals}
                packageId={pkg.id}
                savedMessageFrom={pkg.giftMessage?.from}
                savedMessageBody={pkg.giftMessage?.body}
              />
              <CancelPackageModal
                open={modalOpen === ModalType.CANCEL_PACKAGE}
                handleClose={closeAllModals}
                packageId={pkg.id}
              />
            </>
          )}
        </Container>
      </div>
    </>
  )
}

export default PackageDetailPage
