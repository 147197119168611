import { intersection } from 'lodash'
import { FC, useContext, useState } from 'react'

import Button from 'src/components/01-atoms/Button'

import PackagesContext from 'src/contexts/PackagesContext'
import { IFacilityType, IManifestAggregate, IManifestFilterCategory } from 'src/graphql/types'
import useAppParams from 'src/utils/hooks/useAppParams'
import useMultiFacilityParams from 'src/utils/hooks/useMultiFacilityParams'

import FacilityAssignment from './FacilityAssignment'

interface IManifestActionsProps {
  /**
   * The list of packages selected from the table.
   */
  selectedPackages: Set<string>

  /**
   * The list of facilities that aggregation return.
   * This allows us to find packages that were routed to
   * inactive Facilities
   */
  facilityAggregates: IManifestAggregate[]

  /**
   * Whether or not the actions buttons should be disabled.
   */
  disabled?: boolean

  /**
   * Method to fire when the assignment action is complete.
   */
  handleAssignmentDone?: () => void
}

const ManifestActions: FC<IManifestActionsProps> = ({
  selectedPackages,
  facilityAggregates,
  disabled = false,
  handleAssignmentDone = () => {},
}) => {
  const { mdashAccountId, toShipOnAsString, isTangibleFacility, withFacilityParams } =
    useAppParams()
  const { packages } = useContext( PackagesContext )
  const { shouldDisplayFacilityAssignmentButtons, isPhysicallyActionable, assignableFacilities } =
    useMultiFacilityParams({ facilityAggregates })

  const [ showFacilityAssignmentModal, setShowFacilityAssignmentModal ] = useState( false )

  const actionButtonsDisabled = selectedPackages.size === 0 || disabled
  const printedPackages = packages
    ?.map(( pkg ) => pkg.currentShippingLabel?.printedAt && pkg.id )
    .filter(( v ) => !!v )
  const selectionContainsAlreadyPrinted =
    intersection( Array.from( selectedPackages ), printedPackages ).length > 0
  const facilityParams = withFacilityParams()

  const URLParams = `${new URLSearchParams(
    Array.from( selectedPackages ).map(( id ) => [ 'id', `${id}` ])
  ).toString()}${facilityParams && `&${facilityParams}`}`

  return (
    <div data-testid="manifest-actions" className="flex flex-col md:flex-row gap-2">
      {shouldDisplayFacilityAssignmentButtons && (
        <>
          <Button
            onClick={() => setShowFacilityAssignmentModal( true )}
            disabled={actionButtonsDisabled}
            outline={isTangibleFacility}
          >
            {isTangibleFacility ? 'Reassign to Location' : 'Assign to Location'}
          </Button>
          {showFacilityAssignmentModal && (
            <FacilityAssignment
              open={showFacilityAssignmentModal}
              facilities={assignableFacilities.map(( x ) => ({
                name: x.label!,
                id: x.query!,
                type:
                  x.category === String( IManifestFilterCategory.SHIPPING_FACILITY )
                    ? IFacilityType.SHIPPING_FACILITY
                    : IFacilityType.PICK_UP_LOCATION,
              }))}
              selectedPackages={selectedPackages}
              handleClose={() => {
                setShowFacilityAssignmentModal( false )
              }}
              handleDone={() => {
                setShowFacilityAssignmentModal( false )
                handleAssignmentDone()
              }}
            />
          )}
        </>
      )}
      {isPhysicallyActionable && (
        <>
          <Button
            to={{
              pathname: `/${mdashAccountId}/pick_list/${toShipOnAsString}`,
              search: URLParams,
            }}
            disabled={actionButtonsDisabled}
            outline
          >
            Print Pick List
          </Button>
          <Button
            to={{
              pathname: `/${mdashAccountId}/fulfillment_sheets/${toShipOnAsString}`,
              search: URLParams,
            }}
            outline
            disabled={actionButtonsDisabled}
          >
            Print Fulfillment Sheet
          </Button>
          <Button
            to={{
              pathname: `/${mdashAccountId}/labels/${toShipOnAsString}/${
                selectionContainsAlreadyPrinted ? 'confirm_reprint' : 'print'
              }`,
              search: URLParams,
            }}
            disabled={actionButtonsDisabled}
          >
            Print Slip &amp; Labels
          </Button>
        </>
      )}
    </div>
  )
}

export default ManifestActions
