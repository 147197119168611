import * as Accordion from '@radix-ui/react-accordion'
import classNames from 'classnames'
import { forwardRef, Ref, PropsWithChildren } from 'react'

const CardAccordionContent = forwardRef<HTMLDivElement, PropsWithChildren & { className?: string }>(
  ({ children, className, ...props }, forwardedRef: Ref<HTMLDivElement> ) => (
    <Accordion.Content
      className={classNames(
        '[[data-state="open"]_&]:animate-accordionSlideDown [[data-state="closed"]_&]:animate-accordionSlideUp will-change-height',
        className
      )}
      {...props}
      ref={forwardedRef}
    >
      {children}
    </Accordion.Content>
  )
)

export default CardAccordionContent
