import { ElementType, FC, useState } from 'react'
import { isNil, omitBy } from 'lodash'
import { useMutation } from 'urql'
import { loader } from 'graphql.macro'
import { Link, To, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons'

import Alert from 'src/components/01-atoms/Alert'
import Button from 'src/components/01-atoms/Button'
import LoadingBar from 'src/components/01-atoms/LoadingBar'
import DetailsList from 'src/components/02-molecules/DetailsList'
import { INavDropdownContent } from 'src/components/02-molecules/NavDropdownContent'
import Grid from 'src/components/01-atoms/Grid'
import InlineLink from 'src/components/01-atoms/InlineLink'
import PackageTag, { PackageType } from 'src/components/01-atoms/PackageTag'
import SectionCard from 'src/components/01-atoms/SectionCard'
import NavDropdown from 'src/components/02-molecules/NavDropdown'
import ProductTable from 'src/components/02-molecules/ProductTable'
import { gbmcSuborderLink } from 'src/utils/gbmcLink'
import { IOrderType } from 'src/components/02-molecules/PackageListTableRow'
import PackageTagList from 'src/components/02-molecules/PackageTagList'
import PackageStatus from 'src/components/02-molecules/PackageStatus'

import { dateForManifest, longFormatDayAndDate } from 'src/utils/helpers/date'
import useAppParams from 'src/utils/hooks/useAppParams'

import {
  ICopyPackageMutation,
  ICopyPackageMutationVariables,
} from 'src/graphql/mutations/copyPackage.types'

interface IPackageDetailProps {
  package: {
    id: string
    orderId: string
    completedAt: Date
    recipient: string
    shipTo: string
    shipDate: Date
    deliveryDate: Date
    isCanceled: boolean
    labelPrintedAt?: Date
    labelDiscardedAt?: Date
    labelHasBeenGenerated: boolean
    labelIsPending: boolean
    pickUpLocation?: string | null
    fulfillmentSheetPrintedAt?: Date
    shippingFacility?: string | null
  }
  shippingLabel?: {
    serviceName?: string | null
    trackingCode?: string | null
    trackingLink?: string | null
  }
  printLinks?: {
    fulfillmentSheet?: string
    shippingLabel?: string
  }
  lineItems: {
    id: string
    quantity: number
    name: string
    sku?: string[] | string
    productOptions?: string[]
    specialInstructions?: string | null
  }[]
  orderType?: IOrderType | null
  corporateOrderCompanyName?: string | null
  giftMessage?: {
    body: string
    from?: string
  }
  backLink?: To
  showLinkToGbmc?: boolean
  showFacilityLink?: boolean
  EditOptionsWrapper?: ElementType
  editPackageOptions?: INavDropdownContent['links']
  OrderAgainWrapper?: ElementType
}

const PackageDetail: FC<IPackageDetailProps> = ({
  package: pkg,
  shippingLabel,
  printLinks,
  lineItems,
  orderType,
  corporateOrderCompanyName,
  giftMessage,
  backLink = '',
  showLinkToGbmc,
  showFacilityLink,
  EditOptionsWrapper,
  editPackageOptions = [],
  OrderAgainWrapper,
}) => {
  const tags =
    orderType && !Object.values( orderType ).every(( x ) => x === false )
      ? {
          label: 'Order Type',
          value: (
            <PackageTagList
              isInline
              packageId={pkg.id}
              {...omitBy({ ...orderType, corporateOrderCompanyName }, isNil )}
            />
          ),
        }
      : null

  const facility = showFacilityLink
    ? {
        label: 'Facility',
        value: pkg.pickUpLocation || pkg.shippingFacility || 'Pending Assignment',
      }
    : null

  const disableEdit = pkg.isCanceled || !!pkg.labelPrintedAt || editPackageOptions.length === 0
  const disablePrint = pkg.isCanceled

  const editPackageOptionsDropdown = (
    <div data-testid="edit-package-options-dropdown">
      <NavDropdown
        buttonText={
          <span>
            Edit<span className="sr-only sm:not-sr-only"> Package</span>
          </span>
        }
        buttonProps={{
          disabled: disableEdit,
          title: disableEdit ? 'Cannot edit this package' : '',
        }}
        dropdownContentProps={{
          links: editPackageOptions,
          useArrow: true,
        }}
      />
    </div>
  )

  const [ loadingNewOrder, setLoadingNewOrder ] = useState( false )
  const { mdashAccountId } = useAppParams()
  const copyPackageMutation = loader( 'src/graphql/mutations/copyPackage.graphql' )
  const [ copyPackageResponse, copyPackage ] = useMutation<
    ICopyPackageMutation,
    ICopyPackageMutationVariables
  >( copyPackageMutation )

  const errors =
    copyPackageResponse?.error?.message || copyPackageResponse?.data?.copyPackage?.errors.join( ' ' )

  const { makeLinkUrls } = useAppParams()
  const navigate = useNavigate()

  const handleOrderAgain = () => {
    setLoadingNewOrder( true )
    copyPackage({ packageId: pkg.id, mdashAccountId }).then(( response ) => {
      if (
        response.error ||
        response.data?.copyPackage?.errors.length ||
        !response.data?.copyPackage?.orderId
      ) {
        setLoadingNewOrder( false )
        return
      }

      const checkoutUrl = makeLinkUrls().checkout( response.data.copyPackage.orderId )
      navigate( checkoutUrl )
    })
  }

  return (
    <>
      {errors && (
        <div data-testid="pkg-detail-error">
          <Alert type="error">{errors}</Alert>
        </div>
      )}
      {loadingNewOrder && <LoadingBar current={99} total={100} />}
      <Link
        className="text-gb-gray-800 font-semibold text-xs mb-1"
        to={backLink}
        data-testid="back-link"
      >
        <FontAwesomeIcon icon={faAngleLeft} /> Back to Orders
      </Link>
      <div
        className="mb-6 flex flex-col gap-y-4 md:flex-row md:justify-between"
        data-testid="package-detail-header"
      >
        <div>
          <div className="flex flex-wrap items-center gap-x-2">
            <h1 className="text-2xl font-semibold">Package {pkg.id}</h1>
            {pkg.isCanceled && <PackageTag packageType={PackageType.CANCELED} />}
            {showLinkToGbmc && (
              <a
                className="font-semibold text-sm text-gb-blue-600 hover:text-gb-blue-800"
                data-testid="gbmc-link"
                href={`${gbmcSuborderLink( pkg.id )}`}
              >
                View in GBMC
              </a>
            )}
          </div>
          <h2 className="text-lg">Placed On: {longFormatDayAndDate( pkg.completedAt )}</h2>
        </div>
        <div className="flex gap-2 md:items-start">
          {OrderAgainWrapper && (
            <OrderAgainWrapper>
              <Button
                data-testid="order-again-button"
                onClick={() => {
                  handleOrderAgain()
                }}
                outline
                disabled={loadingNewOrder}
              >
                Order Again
              </Button>
            </OrderAgainWrapper>
          )}
          {EditOptionsWrapper && (
            <EditOptionsWrapper>{editPackageOptionsDropdown}</EditOptionsWrapper>
          )}
          <div data-testid="print-options-dropdown">
            <NavDropdown
              buttonText={
                <span>
                  Print<span className="sr-only sm:not-sr-only"> Options</span>
                </span>
              }
              buttonProps={{
                disabled: disablePrint,
                title: disablePrint ? 'Cannot print this order' : '',
              }}
              dropdownContentProps={{
                links: [
                  {
                    to: printLinks?.fulfillmentSheet || '',
                    text: 'Print Fulfillment Sheet',
                    disabled: !printLinks?.fulfillmentSheet,
                  },
                  {
                    to: printLinks?.shippingLabel || '',
                    text: 'Print Slip & Labels',
                    disabled: !printLinks?.shippingLabel,
                  },
                ],
                useArrow: true,
              }}
            />
          </div>
        </div>
      </div>
      <Grid>
        <SectionCard
          title="Details"
          className={classNames(
            {
              'md:row-span-2': !!giftMessage,
            },
            'col-span-12 md:col-span-6 lg:col-span-4'
          )}
        >
          <div data-testid="section-package-details">
            <DetailsList
              list={[
                { label: 'Customer Order', value: pkg.orderId },
                { label: 'Recipient', value: pkg.recipient },
                { label: 'Ship To', value: pkg.shipTo },
                facility,
                {
                  label: 'Ship Date',
                  value: (
                    <InlineLink
                      href={`../manifest/${dateForManifest( pkg.shipDate )}`}
                      data-testid="manifest-link"
                    >
                      {longFormatDayAndDate( pkg.shipDate )}
                    </InlineLink>
                  ),
                },
                {
                  label: 'Delivery Date',
                  value: longFormatDayAndDate( pkg.deliveryDate ),
                },
                {
                  label: 'Carrier & Speed',
                  value: shippingLabel?.serviceName ? shippingLabel?.serviceName : <>Pending</>,
                },
                {
                  label: 'Shipping Label',
                  value:
                    shippingLabel?.trackingLink && shippingLabel?.trackingCode ? (
                      <InlineLink href={shippingLabel.trackingLink} target="_blank">
                        {shippingLabel.trackingCode}
                      </InlineLink>
                    ) : (
                      <>Pending</>
                    ),
                },
                tags,
                {
                  label: 'Status',
                  value: (
                    <PackageStatus
                      printLink={printLinks?.shippingLabel}
                      showActionLink={false}
                      isCanceled={pkg.isCanceled}
                      labelPrintedAt={pkg.labelPrintedAt}
                      labelDiscardedAt={pkg.labelDiscardedAt}
                      labelHasBeenGenerated={pkg.labelHasBeenGenerated}
                      labelIsPending={pkg.labelIsPending}
                      fulfillmentSheetPrintedAt={pkg.fulfillmentSheetPrintedAt}
                    />
                  ),
                },
              ]}
            />
          </div>
        </SectionCard>
        {giftMessage && (
          <SectionCard
            title="Gift Message"
            className="col-span-12 md:col-span-6 lg:col-span-8 md:row-span-1"
          >
            <div data-testid="section-gift-message">
              <p>{giftMessage?.body}</p>
              {giftMessage.from && <p>&mdash; {giftMessage.from}</p>}
            </div>
          </SectionCard>
        )}
        <SectionCard
          title="Package"
          className="col-span-12 md:col-span-6 lg:col-span-8 md:row-span-1"
        >
          <div data-testid="section-line-items">
            <ProductTable lineItems={lineItems} />
          </div>
        </SectionCard>
      </Grid>
    </>
  )
}

export default PackageDetail
