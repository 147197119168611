import classNames from 'classnames'
import { FC, PropsWithChildren, ReactElement } from 'react'

interface ISectionCardProps {
  /**
   * The title of the section card.
   */
  title?: string | ReactElement

  /**
   * Whether or not there is an error in the section.
   */
  error?: boolean

  /**
   * The size/spacing of the section card.
   */
  size?: 'default' | 'small'

  /**
   * Classes to pass down to the containing element.
   */
  className?: string

  /**
   * Classes to pass down to the content container.
   */
  contentClassName?: string
}

const SectionCard: FC<PropsWithChildren<ISectionCardProps>> = ({
  children,
  title,
  error,
  size = 'default',
  className = '',
  contentClassName = '',
}) => (
  <section
    className={classNames( className, 'rounded border-2 bg-white', {
      'border-inherit': !error,
      'border-red-600': error,
    })}
  >
    {title && (
      <div
        className={classNames( 'p-4 border-b-2 font-bold', {
          'text-lg': size === 'default',
          'text-base': size === 'small',
        })}
      >
        {title}
      </div>
    )}
    <div
      className={classNames( contentClassName, 'text-sm', {
        'px-4 py-6': size === 'default',
        'p-4': size === 'small',
      })}
    >
      {children}
    </div>
  </section>
)

export default SectionCard
