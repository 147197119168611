import classNames from 'classnames'
import { FC } from 'react'

import { centsToUSD } from 'src/utils/helpers/currencies'

import './styles.css'

export interface IOrderSummaryProps {
  /**
   * The subtotal amount for the order, in cents.
   */
  subtotal?: number

  /**
   * The amount for shipping, in cents.
   */
  shipping?: number

  /**
   * The total amount to be paid for the order, in cents.
   */
  total?: number

  /**
   * Just in case we have to support tax too.
   */
  tax?: number

  /**
   * The sum of all of the promo codes on the order, in cents.
   */
  promoAmount?: number

  /**
   * A description of the promo code being applied.
   */
  promoDescription?: string

  /**
   * The sum of the gift cards on the order, in cents.
   */
  giftCardAmount?: number

  /**
   * Classes to pass down to the containing element.
   */
  className?: string
}

const OrderSummary: FC<IOrderSummaryProps> = ({
  subtotal = 0,
  shipping = 0,
  tax = 0,
  total = 0,
  promoAmount = 0,
  promoDescription = '',
  giftCardAmount = 0,
  className,
}) => (
  <dl className={classNames( className, 'grid grid-flow-row grid-cols-2 text-sm' )}>
    <dt>Subtotal</dt>
    <dd data-testid="order-summary-subtotal">{centsToUSD( subtotal )}</dd>
    <dt>Shipping</dt>
    <dd data-testid="order-summary-shipping">{centsToUSD( shipping )}</dd>
    {tax > 0 && (
      <>
        <dt>Tax</dt>
        <dd data-testid="order-summary-tax">{centsToUSD( tax )}</dd>
      </>
    )}
    {promoAmount > 0 && (
      <>
        <dt>Promotion</dt>
        <dd data-testid="order-summary-promo">
          {centsToUSD( promoAmount * -1 )}
          {promoDescription && (
            <div className="text-2xs text-gb-gray-800">({promoDescription})</div>
          )}
        </dd>
      </>
    )}
    {giftCardAmount > 0 && (
      <>
        <dt>Gift Card(s)</dt>
        <dd data-testid="order-summary-gift-card">{centsToUSD( giftCardAmount * -1 )}</dd>
      </>
    )}
    <dt className="font-bold">Total</dt>
    <dd data-testid="order-summary-total" className="font-bold">
      {centsToUSD( total )}
    </dd>
  </dl>
)

export default OrderSummary
