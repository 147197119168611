import { faBoxHeart, faGift, faPlateUtensils } from '@fortawesome/pro-solid-svg-icons'

import Logo, { LogoType } from 'src/components/01-atoms/Logo'
import PrintablePage, { PageSize } from 'src/components/01-atoms/PrintablePage'
import PackageInstructions from 'src/components/01-atoms/PackageInstructions'
import PackageNotice from 'src/components/01-atoms/PackageNotice'
import PackageInfo, { IPackageInfoProps } from 'src/components/02-molecules/PackageInfo'
import PackingSlipSection from 'src/components/01-atoms/PackingSlipSection'

import weShipWithImg from 'src/assets/we-ship-on-stacked.png'
import weShipWithImgv2 from 'src/assets/we-ship-on-stacked-v2.svg'
import checklistIcon from 'src/assets/icons/checklist.svg'
import usaToCan from 'src/assets/usa-to-can.svg'

import PackageContents, { IPackageContentsProps } from 'src/components/02-molecules/PackageContents'
import { FC, ReactElement, createRef, useEffect, useState } from 'react'
import classNames from 'classnames'
import { getInValue } from 'src/utils/helpers/printValueConversions'
import { IVersioned } from 'src/utils/types/Versioned'
import { PackageType } from '../../PackageType'

export interface ISlipNLabelThermal4x6PackingSlipPageProps extends IPackageInfoProps, IVersioned {
  /**
   * The name of the merchant.
   */
  merchantName: string

  /**
   * The type of order being fulfilled. The value of the enum provides the heading text for the
   * order instructions.
   */
  packageType?: PackageType

  /**
   * The URL for the merchant's logo.
   */
  whiteLabelLogoPath?: string | null

  /**
   * The URL to the QR Code image.
   */
  qrCodeImgPath?: string | null

  /**
   * The country where the package is being delivered.
   */
  deliveryCountry: string

  /**
   * The list items in this package.
   */
  packageContents: IPackageContentsProps['lineItems']

  /**
   * The name of the person receiving the package.
   */
  recipientsName: string

  /**
   * The name of the person sending the package.
   */
  sendersName?: string

  /**
   * The gift message from the sender.
   */
  giftMessage?: string

  /**
   * Custom instructions to display on the packing slip.
   */
  customInstructions?: ReactElement | string

  /**
   * Extra classes to add to this element's container, which also contains the border and padding.
   */
  className?: string
}

const PackingSlipPage: FC<ISlipNLabelThermal4x6PackingSlipPageProps> = ({
  merchantName,
  packageType = PackageType.DEFAULT,
  whiteLabelLogoPath,
  qrCodeImgPath,
  deliveryCountry = 'US',
  packageContents,
  recipientsName,
  sendersName,
  giftMessage,
  customInstructions,
  className,
  version = 1,
  ...packageInfoProps
}) => {
  const [ listTooLong, setListTooLong ] = useState( false )
  const contentsElem = createRef<HTMLDivElement>()

  const prescribedHeightInInches = version === 1 ? 2 : 5

  useEffect(() => {
    if ( contentsElem.current?.clientHeight ) {
      setListTooLong( getInValue( contentsElem.current?.clientHeight ) >= prescribedHeightInInches )
    }
  }, [ contentsElem.current?.clientHeight ])

  const hasWhiteLabelLogo = !!whiteLabelLogoPath
  const headingText = packageType
  const hasGiftMessage = !!giftMessage && !!sendersName

  return version === 1 ? (
    <div data-testid="packing-slip-v1">
      <PrintablePage size={PageSize.THERMAL_4x6} className={className}>
        <div className="h-full relative p-[0.2in] font-semibold">
          <div className="w-full flex flex-row gap-x-4 center">
            <div className="flex-grow">
              {hasWhiteLabelLogo ? (
                <img
                  src={whiteLabelLogoPath}
                  alt={`${merchantName} logo`}
                  className="max-h-18 max-w-[1in]"
                />
              ) : (
                <Logo type={LogoType.STACKED} className="max-h-[0.66in] max-w-[1in]" />
              )}
            </div>
            {hasWhiteLabelLogo && (
              <img src={weShipWithImg} className="max-h-18 flex-shrink max-w-[0.6in]" alt="" />
            )}
            <PackageInfo {...packageInfoProps} size="small" className="flex-shrink" />
          </div>

          <div className="pt-2 text-xs font-semibold">
            <div className="font-bold uppercase">{merchantName} Order</div>
            <div className="w-full flex mt-2 gap-x-2">
              <div className="flex place-items-center w-1/5">
                {qrCodeImgPath && (
                  <img
                    src={qrCodeImgPath}
                    alt="QR Code for order instructions"
                    className="w-full aspect-square"
                  />
                )}
              </div>
              <PackageInstructions
                headingText={headingText}
                className="w-4/5"
                size="small"
                showIcons={false}
              >
                {customInstructions || (
                  <>
                    Use your smart phone&apos;s camera to scan the QR Code. Visit{' '}
                    <span className="underline font-bold">help.goldbelly.com</span> for questions.
                  </>
                )}
              </PackageInstructions>
            </div>

            <div className="h-full relative mt-2">
              <div
                className={classNames({ 'max-h-[2in]': listTooLong }, 'w-full overflow-hidden' )}
                ref={contentsElem}
              >
                <PackageContents size="small" lineItems={packageContents} />
              </div>
              {listTooLong && (
                <div className="absolute z-20 inset-x-0 top-[1.55in] rotate-3">
                  <PackageNotice size="small" orderId={packageInfoProps.orderId} />
                </div>
              )}
            </div>

            <div className="mt-5">
              <span className="font-bold uppercase">For:</span> {recipientsName}
              {giftMessage && <p className="my-1 text-2xs max-h-28 break-word">{giftMessage}</p>}
              {sendersName && (
                <p>
                  <span className="font-bold uppercase">From:</span> {sendersName}
                </p>
              )}
            </div>
            <div className="text-2xs w-full absolute inset-x-0 bottom-0 text-center screen:border-b screen:border-dashed screen:border-b-gb-gray-600 screen:mb-1">
              Please include the above slip inside the package
            </div>
          </div>
        </div>
      </PrintablePage>
    </div>
  ) : (
    <div data-testid="packing-slip-v2">
      <PrintablePage size={PageSize.THERMAL_4x6} className={className}>
        <div className="h-full relative p-[0.2in]">
          <div
            ref={contentsElem}
            className="max-h-[5in] flex flex-col gap-y-2 overflow-hidden relative"
          >
            <div className="w-full flex flex-row gap-x-2 items-center relative pb-2">
              {hasWhiteLabelLogo ? (
                <img
                  src={whiteLabelLogoPath}
                  alt={`${merchantName} logo`}
                  className="max-h-12 max-w-[1in]"
                />
              ) : (
                <Logo type={LogoType.STACKED} className="w-full max-h-[0.4in] max-w-[0.7in]" />
              )}
              {hasWhiteLabelLogo && (
                <img src={weShipWithImgv2} className="flex-shrink max-h-[0.35in]" alt="" />
              )}
              <PackageInfo
                {...packageInfoProps}
                className="flex-shrink ml-auto"
                version={version}
                barcodeLocation="top"
              />
              {deliveryCountry === 'CA' && (
                <div className="absolute -bottom-10 -right-[2px] z-10">
                  <img
                    src={usaToCan}
                    className="h-12"
                    data-testid="stamp-ship-to-canada"
                    alt="This package is being shipped to Canada!"
                  />
                </div>
              )}
            </div>

            <PackingSlipSection
              icon={hasGiftMessage ? faGift : faBoxHeart}
              prefix={`For ${recipientsName}`}
              suffix={sendersName && `From ${sendersName}`}
              variant="topBorderOnly"
              nameForTests="gift-message"
            >
              {giftMessage && <p>{giftMessage}</p>}
            </PackingSlipSection>

            <div className="flex gap-x-2">
              <PackingSlipSection
                icon={faPlateUtensils}
                prefix="Prep & Storage Info"
                iconAlignment="center"
                variant="topAndBottomBorder"
                className="flex-grow"
                nameForTests="prep-storage-info"
              >
                <p>Scan the QR code for instructions</p>
              </PackingSlipSection>
              {qrCodeImgPath && (
                <img
                  src={qrCodeImgPath}
                  alt=""
                  className="max-w-18 max-h-18 snap-end"
                  data-testid="section-image"
                />
              )}
            </div>

            <PackingSlipSection
              icon={checklistIcon}
              variant="noBorder"
              className="relative"
              nameForTests="package-contents"
            >
              <PackageContents
                merchantName={merchantName}
                lineItems={packageContents}
                version={version}
              />
            </PackingSlipSection>

            {listTooLong && (
              <div className="absolute z-20 inset-x-0 bottom-0">
                <PackageNotice size="small" orderId={packageInfoProps.orderId} version={version} />
              </div>
            )}
          </div>
          <PackingSlipSection
            className="mt-2"
            variant="topBorderOnly"
            nameForTests="help-and-support"
          >
            <p className="text-3xs">
              {customInstructions || (
                <>
                  Visit <strong>help.goldbelly.com</strong> for order questions!
                </>
              )}
            </p>
          </PackingSlipSection>
          <div className="text-3xs w-full absolute inset-x-0 bottom-0 text-center border-black text-black screen:border-b screen:border-dashed screen:pb-1">
            Please include the above slip inside the package
          </div>
        </div>
      </PrintablePage>
    </div>
  )
}

export default PackingSlipPage
