import { useQuery, UseQueryResponse } from 'urql'
import { loader } from 'graphql.macro'
import { startOfDay } from 'date-fns'

import useAppParams from 'src/utils/hooks/useAppParams'

import {
  IGetManifestCalendarQuery,
  IGetManifestCalendarQueryVariables,
} from 'src/graphql/queries/getManifestCalendar.types'

import { IUseGetManifestVariables } from 'src/utils/types/GetManifestHookQueryTypes'
import { stringAsDate } from '../helpers/date'

const getManifestCalendar = loader( 'src/graphql/queries/getManifestCalendar.graphql' )

interface IGetManifestCalendarQueryWithDates extends IGetManifestCalendarQuery {
  manifestCalendar: {
    toShipOn: Date
    toShipCount: number
  }[]
}

const useGetManifestCalendar = ({
  variables = {},
  ...useQueryOptionOverrides
}: IUseGetManifestVariables<
  IGetManifestCalendarQuery,
  IGetManifestCalendarQueryVariables
> ): UseQueryResponse<IGetManifestCalendarQueryWithDates, IGetManifestCalendarQueryVariables> => {
  const { mdashAccountId, toShipOnAsString: toShipOn } = useAppParams()

  const [ response, refetch ] = useQuery<
    IGetManifestCalendarQuery,
    IGetManifestCalendarQueryVariables
  >({
    query: getManifestCalendar,
    variables: {
      mdashAccountId,
      toShipOn,
      ...variables,
    },
    requestPolicy: 'cache-and-network',
    pause: Number( mdashAccountId || 0 ) < 1,
    ...useQueryOptionOverrides,
  })

  const calendarWithDateObjects = response.data
    ? response.data.manifestCalendar.map(({ toShipCount, toShipOn }) => ({
        toShipCount,
        toShipOn: startOfDay( stringAsDate( toShipOn )),
      }))
    : []

  return [
    { ...response, data: { ...response.data, manifestCalendar: calendarWithDateObjects }},
    refetch,
  ]
}

export default useGetManifestCalendar
