import { FC, SetStateAction, Dispatch, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { OperationResult, useMutation, useQuery } from 'urql'
import { loader } from 'graphql.macro'

import Alert from 'src/components/01-atoms/Alert'
import SectionCard from 'src/components/01-atoms/SectionCard'
import Fieldset from 'src/components/01-atoms/Fieldset'
import AlertList from 'src/components/02-molecules/AlertList'
import { TAvailableProduct } from 'src/components/03-organisms/ShoppingCart/types'
import ShoppingCart from 'src/components/03-organisms/ShoppingCart'

import useCheckoutParams from 'src/utils/hooks/useCheckoutParams'
import useAppParams from 'src/utils/hooks/useAppParams'

import {
  IGetMdashAccountProductsQuery,
  IGetMdashAccountProductsQueryVariables,
} from 'src/graphql/queries/getMdashAccountProducts.types'
import {
  IGetOrderCartItemsQuery,
  IGetOrderCartItemsQueryVariables,
} from 'src/graphql/queries/getOrderCartItems.types'
import {
  ICreateCartItemMutation,
  ICreateCartItemMutationVariables,
} from 'src/graphql/mutations/createCartItem.types'
import {
  IUpdateCartItemMutation,
  IUpdateCartItemMutationVariables,
} from 'src/graphql/mutations/updateCartItem.types'
import {
  IRemoveCartItemMutation,
  IRemoveCartItemMutationVariables,
} from 'src/graphql/mutations/removeCartItem.types'
import ArrayElement from 'src/utils/types/ArrayElement'

const getMdashAccountProductsQuery = loader( 'src/graphql/queries/getMdashAccountProducts.graphql' )
const getOrderCartItemsQuery = loader( 'src/graphql/queries/getOrderCartItems.graphql' )
const createCartItemMutation = loader( 'src/graphql/mutations/createCartItem.graphql' )
const updateCartItemMutation = loader( 'src/graphql/mutations/updateCartItem.graphql' )
const removeCartItemMutation = loader( 'src/graphql/mutations/removeCartItem.graphql' )

export type TProductSelectionCartItem = ArrayElement<IGetOrderCartItemsQuery['order']['cartItems']>

interface ICartProductSelectionProps {
  refetchOrderStatus?: () => void
  cartItems?: TProductSelectionCartItem[]
  setCartItems?: Dispatch<SetStateAction<TProductSelectionCartItem[]>>
}

const CartProductSelection: FC<ICartProductSelectionProps> = ({
  refetchOrderStatus = () => {},
  cartItems = [],
  setCartItems = () => {},
}) => {
  const { orderId, validOrderId } = useCheckoutParams()
  const { mdashAccountId } = useAppParams()
  const { formState } = useFormContext()

  const [{ data: availableProducts, fetching: fetchingAvailableProducts }] = useQuery<
    IGetMdashAccountProductsQuery,
    IGetMdashAccountProductsQueryVariables
  >({
    query: getMdashAccountProductsQuery,
    variables: {
      id: mdashAccountId,
      forGuac: true,
    },
    pause: Number( mdashAccountId ?? 0 ) === 0,
    requestPolicy: 'cache-and-network',
  })

  const [{ data: savedCartItems, fetching: fetchingCartItems }] = useQuery<
    IGetOrderCartItemsQuery,
    IGetOrderCartItemsQueryVariables
  >({
    query: getOrderCartItemsQuery,
    variables: {
      mdashAccountId,
      orderId,
    },
    pause: !validOrderId,
  })

  const [ createErrors, setCreateErrors ] = useState<string[]>([])
  const [ updateErrors, setUpdateErrors ] = useState<string[]>([])
  const [ removeErrors, setRemoveErrors ] = useState<string[]>([])

  const [ createResponse, createCartItem ] = useMutation<
    ICreateCartItemMutation,
    ICreateCartItemMutationVariables
  >( createCartItemMutation )
  const [ updateResponse, updateCartItem ] = useMutation<
    IUpdateCartItemMutation,
    IUpdateCartItemMutationVariables
  >( updateCartItemMutation )
  const [ removeResponse, deleteCartItem ] = useMutation<
    IRemoveCartItemMutation,
    IRemoveCartItemMutationVariables
  >( removeCartItemMutation )

  useEffect(() => {
    if ( !savedCartItems ) return
    setCartItems( savedCartItems.order.cartItems )
  }, [ savedCartItems ])

  // TODO - These 3 can be consolidated into a single useEffect.
  useEffect(() => {
    if ( updateResponse.fetching ) return
    const errors = [
      updateResponse.error?.message ?? '',
      ...( updateResponse?.data?.updateCartItem?.errors ?? []),
    ].filter(( v ) => !!v )
    setUpdateErrors( errors )
  }, [ updateResponse ])

  useEffect(() => {
    if ( createResponse.fetching ) return
    const errors = [
      createResponse.error?.message ?? '',
      ...( createResponse?.data?.createCartItem?.errors ?? []),
    ].filter(( v ) => !!v )
    setCreateErrors( errors )
  }, [ createResponse ])

  useEffect(() => {
    if ( removeResponse.fetching ) return
    const errors = [
      removeResponse.error?.message ?? '',
      ...( removeResponse?.data?.removeCartItem?.errors ?? []),
    ].filter(( v ) => !!v )
    setRemoveErrors( errors )
  }, [ removeResponse ])

  const allErrors: string[] = Array.from(
    new Set([ ...createErrors, ...updateErrors, ...removeErrors ])
  )

  const replaceCartItem = ( cartItem: TProductSelectionCartItem ) => {
    const newCartItems = cartItems.map(( item ) => ( item.id === cartItem.id ? cartItem : item ))
    setCartItems( newCartItems )
  }

  const updateCartItemFromResponse = ( response: OperationResult<IUpdateCartItemMutation> ) => {
    if ( !response.data?.updateCartItem?.cartItem ) return
    replaceCartItem( response.data.updateCartItem.cartItem )
  }

  const fetching =
    fetchingCartItems ||
    fetchingAvailableProducts ||
    !availableProducts ||
    createResponse.fetching ||
    updateResponse.fetching ||
    removeResponse.fetching ||
    formState.isSubmitting

  return (
    <Fieldset disabled={fetching}>
      {!availableProducts && !fetching && (
        <Alert type="error">Could not pull available products.</Alert>
      )}
      <SectionCard size="small" title="Cart">
        {allErrors.length > 0 && <AlertList type="error" alerts={allErrors} className="mb-4" />}
        {availableProducts &&
          availableProducts?.mdashAccount &&
          availableProducts?.mdashAccount?.products.length > 0 && (
            <ShoppingCart
              availableProducts={availableProducts?.mdashAccount?.products as TAvailableProduct[]}
              cartItems={cartItems ?? []}
              onCartItemAddition={({ productId }) =>
                createCartItem({ orderId, productId })
                  .then(( response ) => {
                    if ( !response.data?.createCartItem?.cartItem ) return
                    setCartItems(( cartItems ) => [
                      ...cartItems,
                      response.data?.createCartItem?.cartItem as TProductSelectionCartItem,
                    ])
                  })
                  .finally(() => refetchOrderStatus())
              }
              onCartItemDeletion={({ cartItemId }) =>
                deleteCartItem({ cartItemId })
                  .then(( response ) => {
                    if ( !response.data?.removeCartItem?.cartItem ) return
                    const itemToDelete = response.data.removeCartItem.cartItem
                    const newCartItems = cartItems.filter(( item ) => item.id !== itemToDelete.id )
                    setCartItems( newCartItems )
                  })
                  .finally(() => refetchOrderStatus())
              }
              onProductChange={({ cartItemId, productId }) =>
                updateCartItem({ cartItemId, productId })
                  .then( updateCartItemFromResponse )
                  .finally(() => refetchOrderStatus())
              }
              onProductQuantityChange={({ cartItemId, value }) =>
                updateCartItem({ cartItemId, quantityOrWeight: value })
                  .then( updateCartItemFromResponse )
                  .finally(() => refetchOrderStatus())
              }
              onProductOptionChange={( newCartItems ) => {
                const [{ cartItemId }] = newCartItems
                const variances = newCartItems.map(({ varianceId, optionId }) => ({
                  varianceId,
                  productOptionId: optionId,
                }))
                updateCartItem({ cartItemId, variances })
                  .then( updateCartItemFromResponse )
                  .finally(() => refetchOrderStatus())
              }}
              onSpecialInstructionsChange={({ cartItemId, specialInstructions }) =>
                updateCartItem({ cartItemId, specialInstructions })
                  .then( updateCartItemFromResponse )
                  .finally(() => refetchOrderStatus())
              }
            />
          )}
      </SectionCard>
    </Fieldset>
  )
}

export default CartProductSelection
