import { faChevronUp } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Accordion from '@radix-ui/react-accordion'
import classNames from 'classnames'
import { forwardRef, Ref, PropsWithChildren } from 'react'

const CardAccordionHeader = forwardRef<
  HTMLButtonElement,
  PropsWithChildren & { className?: string }
>(({ children, className, ...props }, forwardedRef: Ref<HTMLButtonElement> ) => (
  <Accordion.Header>
    <Accordion.Trigger
      className={classNames(
        'flex w-full justify-between items-center hover:opacity-80 transition-opacity',
        className
      )}
      {...props}
      ref={forwardedRef}
    >
      <div>{children}</div>
      <div className="self-end justify-self-center">
        <FontAwesomeIcon
          className="font-bold text-lg [[data-state='open']_&]:rotate-180 transition-transform ease-[cubic-bezier(0.88,0,0.12,1)]"
          icon={faChevronUp}
          size="sm"
          aria-hidden
        />
      </div>
    </Accordion.Trigger>
  </Accordion.Header>
))

export default CardAccordionHeader
